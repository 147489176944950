import { useProposal } from '@itau-loans-www/shopping/src/hooks'
import { If } from '@itau-loans-www/shopping/src/components'

import AppFlux from './components/AppFlux'
import WebFlux from './components/WebFlux'

import { useResult } from 'pages/Result/hooks'

const Consorcio = () => {
  useResult({})
  const proposalContext = useProposal()
  const qrCode = proposalContext?.product?.proposal?.qrcode ?? ''

  return (
    <div className="final_screen">
      <If condition={!qrCode} renderIf={<AppFlux />} renderElse={<WebFlux />} />
    </div>
  )
}

export default Consorcio
