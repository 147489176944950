import QrCodeWebFlux from '../../../components/QrCodeWebFlux'
import { steps } from './constants'

const WebFlux = () => {
  return (
    <QrCodeWebFlux
      title="Continue o seu consórcio, é só baixar o app e continuar de onde parou"
      steps={steps}
    />
  )
}

export default WebFlux
