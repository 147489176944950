import { IdsMainButton, IdsIcon } from '@ids/react'
import { useProposal } from '@itau-loans-www/shopping/src/hooks'
import { SeeOffersButton } from '@itau-loans-www/shopping/src/components'
import Layout from '@itau-loans-www/shopping/src/layouts/Summary'
import analytics from '@mobi/libraries/analytics'

import StepsComponent from '../../../components/StepsComponent'
import './app-flux.scss'
import { steps } from './data'

const AppFlux = () => {
  const proposalContext = useProposal()

  const link = proposalContext?.product?.proposal?.link
  const productCode = proposalContext?.product?.proposal?.productCode

  return (
    <Layout>
      <div className=" app-flux">
        <h1 className="app-flux__title">
          que tal continuar sua <br /> simulação pelo app do Itaú
        </h1>
        <h2 className="app-flux__subtitle">
          Veja quais vantagens que você encontrará por lá:
        </h2>
        <StepsComponent stepsList={steps} />

        <div className="final_screen__buttons-wrapper">
          <a href={link} target="_blank" rel="noreferrer">
            <IdsMainButton
              full
              id="consorcio-web-app-fallback"
              onClick={() => {
                analytics.track('buttonClick', {
                  currentPage: 'telas-finais-de-redirect-do-produto',
                  detail: productCode
                })
              }}
            >
              Acessar app Itaú
              <IdsIcon>link_externo_outline</IdsIcon>
            </IdsMainButton>
          </a>
          <SeeOffersButton isCrossSell />
        </div>
      </div>
    </Layout>
  )
}

export default AppFlux
