export const steps = [
  {
    text: 'Ofertas exclusivas que você encontrará por lá',
    icon: 'download_base'
  },
  {
    text: 'Pague via débito automático',
    icon: 'juros'
  },
  {
    text: 'preenchimento simplificado dos campos para a contratação',
    icon: 'piscada_outline'
  },
  {
    text: 'Dê lance, acompanhe assembleias e mais pelo app',
    icon: 'piscada_outline'
  }
]
